import React, { useState, useEffect, useCallback, useMemo } from 'react';
import querystring from 'query-string';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
// import debounce from 'lodash/debounce';
import ObjectListFacets from './ObjectListFacets';
import trans from './trans';
import SearchObjectListTableContent from './SearchObjectListTableContent';
import SuggestionFilter from './SuggestionFilter';
import { useSelector } from 'react-redux';
// import { SelectCategory } from './SearchBar';

const OBJECT_TYPE_SORT_ORDER = ['A11', 'B10', 'B01', 'B07', 'B04', 'B02', 'B11', 'B06', 'B14'];

const AdvanceSearchText = styled.div`
  font-weight: bold;
  color: #00677f;
`;
function AdvancedSearch({ isGlobalSearchresultsView, addFilter }) {
  const [collapsed, setCollapsed] = useState(false);
  const toogle = () => {
    setCollapsed(!collapsed);
  };
  if (!isGlobalSearchresultsView) {
    return null;
  }
  const text = !collapsed ? trans('ADVANCED_SEACH') : trans('SHOW_LESS');
  return (
    <React.Fragment>
      <AdvanceSearchText role="button" onClick={toogle}>
        {text}
      </AdvanceSearchText>
      {collapsed && isGlobalSearchresultsView ? (
        <React.Fragment>
          {/* <SelectCategory selected_doctypes={selected_doctypes} toogleCategorie={toogleCategorie} /> */}
          <SuggestionFilter addFilter={addFilter} />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
const ObjectFacetContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  flex: 2;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const FilterText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
const SearchHolder = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const SearchIcon = styled.span`
  color: rgb(153, 153, 153);
  margin-right: 10px;
  position: absolute;
  top: 32px;
  left: 15px;
`;
export const SearchInput = styled.input`
  width: 100%;
  background-color: #eff1f4;
  padding: 15px 35px;
  margin: 15px 0px;
  border-radius: 5px;
  border: 2px solid #eff1f4;
  :focus {
    border: ${(props) => `2px solid ${props.theme.primary}`};
    outline: none;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const FilterModalBody = styled.div`
  height: 100%;
  width: 80%;
  overflow: auto;
  background-color: white;
  padding: 30px;
`;

const FilterModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  right: 0px;
  top: 0px;
  @media (min-width: 768px) {
    display: none;
  }
  z-index: 1000;
`;

const FilterButton = styled.div`
  color: #00677f;
  margin-left: 10px;
  font-weight: bold;
  @media (min-width: 769px) {
    display: none;
  }
`;
const TableCondensed = styled.table`
  margin-bottom: 0;
  & > tbody > tr > th {
    background-color: #fff;
    border-top: 2px solid #eeeff2;
  }
  & > tbody > tr > td:first-child {
    padding: 10px 20px;
  }
  & > tbody > tr:nth-child(odd) > td {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & > tbody > tr:nth-child(even) > td {
    background-color: grey;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f2f3f6;
    border-top: 1px solid #eeeff2;
    border-bottom: 1px solid #eeeff2;
  }
`;

export default function SearchObjectList({
  isCompanySearchResult,
  isGlobalSearchresultsView,
  newItemSelected,
  changeItemSelected,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [filterIsOpen, filterIsOpenSet] = useState(false);

  const { currentuserIsLoggedIn } = useOutletContext()
  const objectSingleUrlTemplatePrivate = useSelector((state) => state.config.objectSingleUrlTemplatePrivate);
  const hideEntriesFromDatabases = useMemo(() => (objectSingleUrlTemplatePrivate && !currentuserIsLoggedIn) ? objectSingleUrlTemplatePrivate : [], [currentuserIsLoggedIn, objectSingleUrlTemplatePrivate])

  const theme = useSelector(state => state.config.theme)
  const {
    filteredResults,
    loaded,
    count,
    actual,
    allResults,
    key,
    doctypes,
    selected_doctypes,
    authors,
    selected_authors,
  } = useSelector(state => state.search)

  const updateQuery = useCallback((value) => {
    const searchNew = querystring.stringify({
      q: value,
      // selected_doctypes,
      type: 'A11',
      field: 'f036t_Gueltigkeitsdatum_ISO',
      _k: 'xte',
      desc: true,
    })
    navigate({ pathname: '/search', search: searchNew });
  }, [navigate]);

  const [results, setResults] = useState(null);
  const [currentsearchquery, currentsearchquerySet] = useState(null);

  // const [selected_doctypes, setSelected_Doctypes] = useState([]);

  useEffect(() => {
    if (!filteredResults) {
      return
    }

    const locationSearchParsed = querystring.parse(location.search);
    const { type, field, desc } = locationSearchParsed;

    // reorder results so that we have a lists with an object for each type,
    // e.g. [ {type: "A11", searchField: '', desc: true/false,  list: [...]}, ... ]
    const searchField = field ? field : 'n005t_Bezeichnung';
    const restructeredResults = filteredResults
      .map((v) => v.element.f001t_Typ)
      .filter((item, pos, self) => self.indexOf(item) === pos)
      .map((categorytype) => ({
        type: categorytype,
        searchField: categorytype === type ? searchField : 'n005t_Bezeichnung',
        desc: desc ? true : false,
        list: filteredResults.filter((res) => res.element.f001t_Typ === categorytype).filter(res => !hideEntriesFromDatabases.includes(res.db)),
      }));

    const orderedResults = [];
    OBJECT_TYPE_SORT_ORDER.forEach((type) => {
      const nextIndex = restructeredResults.findIndex((v) => v.type === type);
      if (nextIndex >= 0) {
        orderedResults.push(...restructeredResults.splice(nextIndex, 1));
      }
    });
    const resultsFormat = [...orderedResults, ...restructeredResults];

    setResults(resultsFormat);
    currentsearchquerySet(newItemSelected ? '' : locationSearchParsed.q);
    if (newItemSelected) {
      changeItemSelected(false);
    }

  }, [filteredResults, newItemSelected, location.search, changeItemSelected, hideEntriesFromDatabases]);

  // const toogleCategorie = (c) => {
  //   const categorieIndex = selected_doctypes.indexOf(c);
  //   if (categorieIndex === -1) {
  //     setSelected_Doctypes([...selected_doctypes, c]);
  //   } else {
  //     selected_doctypes.splice(categorieIndex, 1);
  //     setSelected_Doctypes([...selected_doctypes]);
  //   }
  // };

  const addFilter = useCallback((newFilter) => {
    if (currentsearchquery == null) {
      currentsearchquerySet(newFilter);
      updateQuery(newFilter);
      return;
    }
    if (currentsearchquery.indexOf(newFilter) === -1) {
      updateQuery(newFilter);
      currentsearchquerySet(newFilter);
    }
  }, [currentsearchquery, updateQuery])

  const onChange = useCallback(e => {
    currentsearchquerySet(e.target.value);
    if (isGlobalSearchresultsView) {
      updateQuery(e.target.value)
    }
  }, [isGlobalSearchresultsView, updateQuery])

  const value = useMemo(() => {
    if (isGlobalSearchresultsView) {
      if (currentsearchquery !== null) {
        return currentsearchquery
      }
      return '' || querystring.parse(location.search).q
    } return currentsearchquery
  }, [location.search, currentsearchquery, isGlobalSearchresultsView])

  const getList = useCallback(e => {
    if (isGlobalSearchresultsView) {
      return e.filter(type => type)
    }
    return e
      .map((category) => {
        const list = category.list.filter(
          (c) =>
            !currentsearchquery || c.element.n005t_Bezeichnung.toUpperCase().indexOf(currentsearchquery.toUpperCase()) !== -1
        );
        return {
          ...category,
          list,
        };
      })
      .filter((category) => category.list.length).filter(type => type)
  }, [currentsearchquery, isGlobalSearchresultsView])

  const searchLoading = useMemo(() => !loaded || !results || results.length <= 0, [results, loaded])
  const localResults = useMemo(() => getList(results || []), [getList, results])
  const allResultsForSearchKey = useMemo(() =>
    allResults[key] ? allResults[key].data.filter((v) => v) : [],
    [allResults, key]);

  return (
    <div
      style={{
        backgroundColor: theme.searchResultsBg,
        marginTop: '0px',
        paddingTop: isCompanySearchResult ? '15px' : '0px',
        paddingBottom: '0px',
      }}
    >
      <div style={{ display: 'flex' }}>
        {isCompanySearchResult ? null : (
          <ObjectFacetContainer>
            <ObjectListFacets
              doctypes={doctypes}
              selected_doctypes={selected_doctypes}
              authors={authors}
              selected_authors={selected_authors}
              allResultsForSearchKey={allResultsForSearchKey}
            />
          </ObjectFacetContainer>
        )}
        <div style={{ flex: 8, margin: '0 10px' }}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SearchHolder>
                <SearchIcon className="glyphicon glyphicon-search" />
                <SearchInput value={value} onChange={onChange} placeholder="Suche in Ergebnissen" />
                <div>
                  <FilterButton onClick={() => filterIsOpenSet(true)}>
                    <span className="glyphicon glyphicon-filter" aria-hidden="true" />
                    <FilterText>{trans('FILTER')}</FilterText>
                  </FilterButton>
                  {filterIsOpen ? (
                    <FilterModalContainer>
                      <FilterModalBody>
                        <ObjectListFacets
                          doctypes={doctypes}
                          selected_doctypes={selected_doctypes}
                          authors={authors}
                          selected_authors={selected_authors}
                          allResultsForSearchKey={allResultsForSearchKey} />
                        <button className="btn btn-success btn-large" onClick={() => filterIsOpenSet(false)}>
                          {trans('CLOSE')}
                        </button>
                      </FilterModalBody>
                    </FilterModalContainer>
                  ) : null}
                </div>
              </SearchHolder>
              <AdvancedSearch addFilter={addFilter} isGlobalSearchresultsView={isGlobalSearchresultsView} />
            </div>
            {searchLoading ? (<div>{trans('loader.loadingsearch')} "{currentsearchquery}"...</div>) : (
              (count === 0) ? (
                isCompanySearchResult ? null : trans('search.no-results')
              ) : (
                <TableCondensed className="table table-condensed">
                  <tbody>
                    <SearchObjectListTableContent status={actual} localResults={localResults} />
                  </tbody>
                </TableCondensed>
              )
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
}